// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/svg/dot_menu.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./header-title-choose-wallet.scss"></require>
  <section id="headertitle"
    class.bind="currentInstruction.config.auth && currentInstruction.config.navModel.isActive ? '' : 'no_display'">
    <div class="breadcrumbs">
      <ul>
        <li repeat.for="breadcrumb of breadcrumbs" click.delegate="router.navigateToRoute(breadcrumb.route)">
          <a href="javascript:void(0)" class.bind="breadcrumb.isActive ? 'active' : ''">
            \${breadcrumb.name}
          </a>
          <span if.bind="!\$last"> > </span>
        </li>
      </ul>
    </div>
    <div class="flex-column">
      <div class="flex-row top">
        <div class="left">
          <div class="header_menu">
            <h1>\${title}</h1>
            <div class="flex-grow"></div>
            <div class="dropdown">
              <!-- <ul if.bind="isDropDown">
                <li if.bind="!isEditing" t="profile.choose.choose_wallet.edit_account" click.delegate="isEditing = !isEditing"></li>
                <li if.bind="isEditing" t="contacts.stop_editing" click.delegate="isEditing = !isEditing">></li>
                <li click.delegate="addAccount()" t="profile.choose.choose_wallet.add_account"></li>
              </ul> -->
              <img src="${___HTML_LOADER_IMPORT_0___}" click.delegate="isDropDown = !isDropDown"/>
            </div>
          </div>
          <p class="subtitle" t="components.header-title.choose_wallet.subtitle"></p>
        </div>
        <div class="flex-grow"></div>
        <div class="right flex-row desktop">
          <button id="choose_wallet-edit" class="btn-fresh btn-fresh-secondary" if.bind="!isEditing" t="profile.choose.choose_wallet.edit_account" click.delegate="isEditing = !isEditing"></button>
          <button id="choose_wallet-stop_editing" class="btn-fresh btn-fresh-secondary" if.bind="isEditing" t="contacts.stop_editing" click.delegate="isEditing = !isEditing"></button>
          <button id="choose_wallet-add_account"class="btn-fresh btn-fresh-primary" click.delegate="addAccount()" t="profile.choose.choose_wallet.add_account">Add Account</button>
          <!-- <button class="btn-fresh btn-fresh-secondary last">...</button> -->
        </div>
      </div>
      <div class="flex-row bottom">
        <ul>
          <li 
            repeat.for="menuItem of menuItems"
            class.bind="(tabs === \$index) ? 'active' : ''"
            click.delegate="tabs = \$index">
            <span class="name">
              \${menuItem.name}
            </span>
            <span class="notifs" if.bind="menuItem.notifications > 0">
              \${menuItem.notifications}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
`;
// Exports
export default code;