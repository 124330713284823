import { DialogCloseResult, DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { NavigationInstruction, PipelineResult, Router, RouterEvent } from 'aurelia-router';
import { connectTo } from 'aurelia-store';
import { MenuItem } from 'components/models/MenuItem';
import { Add } from 'pages/contacts/add/add';
import { State } from 'state';
  
  @autoinject()
  @connectTo()
  export class HeaderTitleContact {
    message: string;
    @bindable private title: string;
    @bindable private subtitle: string;
    @bindable private menuItems: Array<MenuItem> = [];
    @bindable private isEditing: boolean = false;
    @bindable private isDropDown: boolean= false;
    private navEvent;
    private currentInstruction;
    private currentRouteParam;
    private breadcrumbs = [];
    
    constructor(private router: Router, private ea: EventAggregator, private dialogService: DialogService, private i18n: I18N, private state: State) { //Initialize the page
      this.navEvent = this.ea.subscribe(RouterEvent.Success, (event: { instruction: NavigationInstruction; result: PipelineResult }) => {
        // console.log("current route: " + JSON.stringify(event.instruction.params));
        // console.log(event.result)
        this.currentInstruction = event.instruction 
        this.currentRouteParam = this.currentInstruction.params;
      });
    }

    addContact() {
      this.dialogService.open({ viewModel: Add, lock: false })
    }

    addBeneficiary() {
      this.router.navigateToRoute("create_recipients")
    }

    active() {}

    bind() {
      this.updateBreadcrumbs();
    }

    updateBreadcrumbs() {
      const instruction = this.currentInstruction || this.router.currentInstruction;
  
      if (!instruction || !instruction.config) {
        console.error("L'instruction est undefined ou mal définie.");
        return; 
      }

      const firstBreadcrumb = this.state.makePayment.isMK
        ? { name: this.i18n.tr("bank.payment.make_payments"), route: 'payments', isActive: instruction.config.route === 'payments' }
        : { name: this.i18n.tr("components.footer-bottom.home"), route: 'home', isActive: instruction.config.route === 'home' };

  
      this.breadcrumbs = [
        firstBreadcrumb,
        { name: this.i18n.tr("contacts.contacts_emoji"), route: "contacts/:page", isActive: instruction.config.route === "contacts/:page"},
      ];
    }

    deactivate() {
      this.navEvent.dispose();
    }
  }
  